.support-wrapper {
  position: static;
  width: 100vw;
  height: 567px;
  left: 0px;

  /* mojitok Blue l 200 */

  background: #3278ff;

  /* Inside Auto Layout */

  flex: none;
  order: 6;
  flex-grow: 0;
  margin: 0px 0px;
}
.support-lists {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-left: 725px;
  margin-top: 120px;
  position: absolute;
  width: 460px;
  height: 327px;
}
.support-text-head {
  position: static;
  width: 331px;
  height: 72px;
  left: 40.5px;
  top: 0px;

  /* Title/Headline 1 l 48px */

  font-family: Gmarket Sans TTF;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 150%;
  /* identical to box height, or 72px */

  text-align: center;

  /* Greyscale/White */

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 32px 0px;
}
.support-text-body {
  position: static;
  width: 412px;
  height: 43px;
  left: 0px;
  top: 104px;

  /* Body/Body 1 l 24px */

  font-family: Gmarket Sans TTF;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 180%;
  /* or 43px */

  text-align: center;

  /* Greyscale/White */

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.support-img {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 460px;
  height: 100px;
  left: 0px;
  top: 227px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 80px 0px;
}
