.store-wrapper {
  position: relative;
  width: 100vw;
  height: 1874px;
  left: 0px;
  background: #f5f8ff;

  /* Inside Auto Layout */

  flex: none;
  order: 5;
  flex-grow: 0;
  margin: 0px 0px;
}
.store-api-lists {
  position: absolute;
  display: flex;
  width: 1320px;
  height: 924px;
  left: 300px;
  top: 456px;
}
.store-api-button {
  width: 640px;
  height: 924px;
  left: 0px;
  top: 0px;
  margin-left: 40px;

  /* Greyscale/White */

  background: #ffffff;
  border-radius: 24px;
}
.store-api-button-head {
  width: 396px;
  height: 48px;
  left: 122px;
  top: 594px;
  margin: auto;
  /* Title/Subtitle 2 l 32px */
  margin-bottom: 24px;
  font-family: Gmarket Sans TTF Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
  /* identical to box height, or 48px */

  text-align: center;

  /* mojitok Navy */

  color: #233653;
}
.store-api-button-body {
  width: 499px;
  height: 58px;
  left: 71px;
  top: 666px;
  margin: auto;

  /* Body/Body 3 l 18px */

  font-family: Gmarket Sans TTF;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  /* or 29px */

  text-align: center;

  /* mojitok Navy */

  color: #233653;
}
.store-api-button-img {
  width: 500px;
  height: 400px;
  margin-left: 61px;
  margin-top: 90px;
  margin-bottom: 104px;
}
.store-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 1073px;
  height: 214px;
  left: 423px;
  top: 140px;
}
.store-text-head {
  position: static;
  width: 752px;
  height: 96px;
  left: 160.5px;
  top: 0px;

  font-family: Gmarket Sans TTF Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 150%;
  /* identical to box height, or 96px */

  text-align: center;

  /* mojitok Blue l 300 */

  color: #2164e4;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.store-text-body {
  position: static;
  width: 1073px;
  height: 86px;
  left: 0px;
  top: 128px;

  /* Body/Body 1 l 24px */

  font-family: Gmarket Sans TTF;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 180%;
  /* or 43px */

  text-align: center;

  /* mojitok Navy */

  color: #233653;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 32px 0px;
  margin-bottom: 82px;
}
.store-text-bottom {
  position: absolute;
  width: 1073px;
  height: 174px;
  top: 1540px;
  margin-left: 424px;
  /* Title/Subtitle 1 l 48px */

  font-family: Sansita One;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 120%;
  /* or 58px */

  text-align: center;

  /* mojitok Sky */

  color: #a1caff;
}
