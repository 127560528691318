.sellers-wrapper {
  position: relative;
  width: 100vw;
  height: 480px;

  /* Greyscale/White */

  background: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 10;
  flex-grow: 0;
  margin: 0px 0px;
}
