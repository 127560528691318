.header-wrapper {
  position: absolute;
  width: 100vw;
  height: 100px;
  left: 0px;
  top: 0px;
}

.logo-mojitok {
  position: absolute;
  width: 124px;
  height: 100px;
  left: 12.5vw;
  top: 0px;
}

.header-contact {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 48px;
  width: 220px;
  height: 64px;
  right: 12.5vw;
  top: 23px;

  /* Greyscale/White */

  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
}

.header-contact-text {
  position: static;
  height: 32px;
  left: 24.5px;
  top: 16px;

  /* Button/Button l 18px */

  font-family: Gmarket Sans TTF Bold;
  font-style: normal;
  font-size: 18px;
  line-height: 180%;
  /* or 32px */

  display: flex;
  align-items: center;
  letter-spacing: 0.04em;

  /* Greyscale/White */

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
}

.header-menu {
  position: absolute;
  display: flex;
  height: 24px;
  width: 580px;
  left: 496px;
  top: 38px;
}

.header-menu__button {
  font-family: Gmarket Sans TTF;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Greyscale/White */

  color: #ffffff;

  /* Greyscale/White */
}
