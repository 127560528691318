.footer-wrapper {
  position: relative;
  width: 100vw;
  height: 452px;

  /* Greyscale/White */

  background: #ffffff;
}
.footer-menu-wrapper {
  position: static;
  width: 100vw;
  height: 372px;
  flex: none;
  order: 12;
  flex-grow: 0;
  margin: 0px 0px;
}
.footer-menu {
  position: absolute;
  display: flex;
  width: 1319px;
  height: 195px;
  left: 240px;
  top: 82px;
}
.mojitok-menu {
  position: absolute;
  width: 100px;
  height: 26.15px;
  left: 0px;
  top: 0px;
}
.footer-menu-content-head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 123px;
  height: 193px;
  margin-right: 124px;

  font-family: Gmarket Sans TTF;
  font-style: Bold;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #233653;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.footer-menu-content-body {
  font-family: Gmarket Sans TTF;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  margin-top: 39px;
  /* or 25px */

  color: rgba(35, 54, 83, 0.5);
}
.footer-menu-policy {
  position: static;
  width: 100vw;
  height: 80px;
  left: 0px;
  top: 14884px;
  /* Greyscale/White */

  background: #ffffff;
  box-shadow: inset 0px 0.5px 0px rgba(35, 54, 83, 0.2);
}
