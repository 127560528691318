.partnership_wrapper {
  position: relative;
  width: 100vw;
  height: 300px;
  background: #ffffff;
  margin: 0px;
}

.partnership_lists {
  position: absolute;
  display: flex;
  height: 140px;
  top: 80px;
  margin: 0px;
}

.partnership-lists-content {
  margin-right: 32px;
}

.partnerships-wrapper {
  position: relative;
  width: 100vw;
  height: 300px;
  background: #ffffff;
  margin: 0px;
}
.partnershipz-lists {
  position: absolute;
  display: flex;
  height: 140px;
  top: 80px;
  margin: 0px;
}
