/* use cases */
.usecase-wrapper {
  background: #f9f9f9;
}

.usecase-title {
  font-family: Gmarket Sans TTF Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  max-width: 1000px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.006em;
  color: #101010;
  margin: auto;
  padding: 64px 0px;
}
.usecase-img {
  width: 100%;
}

.usecase-text {
  font-family: Gmarket Sans TTF Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #3278ff;
  margin-bottom: 66px;
}
