.emailcontact-wrapper {
  position: relative;
  width: 100vw;
  height: 1147px;
  left: 0px;

  /* Greyscale/White */

  background: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 9;
  flex-grow: 0;
  margin: 0px 0px;
}
.emailcontact-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 575px;
  height: 219px;
  left: 240px;
  top: 160px;
}
.emailcontact-text-head {
  position: static;
  width: 575px;
  height: 144px;
  left: 0px;
  top: 0px;

  /* Title/Headline 1 l 48px */

  font-family: Gmarket Sans TTF Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 150%;
  /* or 72px */

  /* mojitok Navy */

  color: #233653;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 32px 0px;
}
.emailcontact-text-body {
  position: static;
  width: 543px;
  height: 43px;
  left: 0px;
  top: 176px;

  /* Body/Body 1 l 24px */

  font-family: Gmarket Sans TTF;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 180%;
  /* or 43px */

  text-align: center;

  /* mojitok Navy */

  color: #233653;
}
.emailcontact-img {
  position: absolute;
  width: 725px;
  height: 569px;
  left: 152px;
  top: 419px;
}
.emailcontact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 560px;
  height: 830px;
  right: 250px;
  top: 160px;
}
.contact-form-wrapper {
  margin: 64px 40px auto auto;
  background: #ffffff;
  width: 452px;
  height: 644px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
}

.contact-form-inner-wrapper {
  padding: 32px;
  text-align: start;
}

.contact-form-label {
  font-family: Gmarket Sans TTF;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  /* identical to box height, or 29px */

  display: flex;
  align-items: center;

  /* mojitok Navy */

  color: #233653;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 15px 0px;
}

.contact-form-label.required::after {
  content: " *";
  color: #ff6f50;
}
.emailcontact-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: static;
  width: 323px;
  height: 80px;
  left: 118.5px;
  top: 750px;

  /* mojitok Blue l 200 */

  background: #3278ff;
  border-radius: 8px;

  /* Inside Auto Layout */
  font-family: Gmarket Sans TTF;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto;
  margin-top: 76px;
}
