/* .useage-counter-wrapper {
  position: relative;
  width: 100vw;
  height: 1170px;
  left: 0px;
  background: #ffffff;
 
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 0px;
} */
.useage-counter__text {
  margin-top: 80px;
  font-family: Sansita One;
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 120%;
  /* or 86px */

  text-align: center;

  /* mojitok Blue l 300 */

  color: #2164e4;
}
.useage-counter__explain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 657px;
  height: 398px;
  right: 240px;
  top: 400px;
}
.useage-counter__explain-head {
  position: static;
  width: 773px;
  height: 72px;
  left: 0px;
  top: 0px;

  /* Title/Headline 1 l 48px */

  font-family: Gmarket Sans TTF Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 150%;
  /* identical to box height, or 72px */

  /* mojitok Navy */

  color: #233653;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.useage-counter__explain-body {
  position: static;
  width: 711px;
  height: 86px;
  left: 0px;
  top: 104px;
  /* Body/Body 1 l 24px */
  font-family: Gmarket Sans TTF;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 180%;
  /* or 43px */
  /* mojitok Navy */
  color: #233653;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 32px 0px;
}
.useage-counter__img {
  position: absolute;
  width: 1420px;
  height: 910px;
  left: 0px;
  bottom: 0px;
}
.useage-counter__number {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 368px;
  height: 110px;
  left: 0px;
  top: 288px;
}
.useage-counter__number-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 149px;
  height: 110px;
  left: 0px;
  top: 0px;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 105px;
}
.useage-counter__number-text-head {
  position: static;
  height: 58px;
  left: 0px;
  top: 0px;

  /* Title/Subtitle 1 l 48px */

  font-family: Sansita One;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 120%;
  color: #3278ff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 9px 0px;
}
.useage-counter__number-text-body {
  position: static;
  width: 114px;
  height: 43px;
  left: 0px;
  top: 67px;
  font-family: Gmarket Sans TTF;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #233653;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 9px 0px;
}
