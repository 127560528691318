.title-stickers {
  position: absolute;
  width: 100vw;
  height: 1200px;
  left: 0px;
  top: 0px;
  z-index: -1;
}
.title-wrapper {
  position: relative;
  width: 100vw;
  height: 1200px;
  background-color: #39a6ff;
  z-index: -1;
}
.title-phones {
  position: absolute;
  width: 52.5vh;
  height: 980px;
  right: 0px;
  top: 220px;
  z-index: -1;
}
.title-headline {
  position: absolute;
  width: 644px;
  height: 313px;
  left: 240px;
  top: 443px;
}
.title-headline__frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}
.title-headline__frame-a {
  position: static;
  width: 534px;
  height: 144px;
  left: 0px;
  top: 0px;
  display: inline-block;
  /* Title/Headline 1 l 48px */

  font-family: Gmarket Sans TTF Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 150%;
  /* or 72px */
  color: #ffffff;
  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 40px 0px;
}
.title-headline__frame-aa {
  color: #ffdc23;
  display: inline-block;
}

.title-headline__frame-b {
  position: static;
  width: 644px;
  height: 129px;
  left: 0px;
  top: 184px;

  /* Body/Body 1 l 24px */

  font-family: Gmarket Sans TTF;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 180%;
  /* or 43px */
  /* Greyscale/White */
  color: #ffffff;
  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 40px;
}
.title-get-started {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 88px;
  position: absolute;
  width: 280px;
  height: 80px;
  /* Greyscale/White */
  background: #ffffff;
  /* Greyscale/Grey 1 */
  border: 1px solid #d3d7dd;
  box-sizing: border-box;
  border-radius: 8px;
}
.title-get-started-text {
  position: static;

  height: 32px;
  left: 63.5px;
  top: 24px;

  /* Button/Button l 18px */

  font-family: Gmarket Sans TTF Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 180%;
  /* or 32px */

  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* mojitok Blue l 200 */

  color: #3278ff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
.title-phones-video {
  width: inherit;
}
