.swiper-slide {
  opacity: 0.3;
}

.swiper-slide-prev {
  opacity: 0.5;
}

.swiper-slide-next {
  opacity: 0.5;
}

.swiper-slide-active {
  opacity: 1;
}
