.stickers_wrapper {
  position: relative;
  width: 100vw;
  height: 820px;
  left: 0px;
  background: radial-gradient(
    339.64% 342.93% at 100% 0%,
    #0067ed 0%,
    #00275a 81.72%
  );
  /* Inside Auto Layout */

  flex: none;
  order: 3;
  flex-grow: 0;
  margin: 0px 0px;
}
.stickers-explain {
  /* Auto Layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 519px;
  height: 340px;
  left: 240px;
  top: 241px;
}
.stickers-wrapper {
  position: relative;
  width: 100vw;
  height: 820px;
  left: 0px;
  background: radial-gradient(
    339.64% 342.93% at 100% 0%,
    #0067ed 0%,
    #00275a 81.72%
  );
  /* Inside Auto Layout */

  flex: none;
  order: 3;
  flex-grow: 0;
  margin: 0px 0px;
}
.stickers__explain {
  /* Auto Layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 519px;
  height: 340px;
  left: 240px;
  top: 241px;
}
.stickers__explain-head {
  position: static;
  width: 476px;
  height: 72px;
  left: 0px;
  top: 0px;

  /* Title/Headline 1 l 48px */

  font-family: Gmarket Sans TTF Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 150%;
  /* identical to box height, or 72px */

  /* Greyscale/White */

  color: #ffffff;
}
.stickers__explain-body {
  position: static;
  width: 519px;
  height: 96px;
  left: 0px;
  top: 104px;

  /* Body/Body 2 l 32px */

  font-family: Sansita One;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 150%;
  /* or 48px */

  /* Greyscale/White */

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 32px 0px;
}
.stickers__img {
  position: absolute;
  width: 1068px;
  height: 820px;
  right: 0px;
  top: 0px;
}
.stickers-video {
  height: 820px;
  right: 0px;
}
.stickers-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 88px;

  position: static;
  width: 280px;
  height: 80px;

  /* Greyscale/White */

  background: #ffffff;
  /* Greyscale/Grey 1 */

  border: 1px solid #d3d7dd;
  box-sizing: border-box;
  border-radius: 8px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 60px 0px;
}
.stickers-button-text {
  position: static;

  height: 32px;
  left: 67.5px;
  top: 24px;

  /* Button/Button l 18px */

  font-family: Gmarket Sans TTF Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 180%;
  /* or 32px */

  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* mojitok Blue l 200 */

  color: #3278ff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
